import { BackendMethod } from '@memberspot/shared/model/backend';

import { GUIDE_VERSION } from './admin-onboarding.model';
import { AdminOnboardingCompletedDto } from './dto/admin-onboarding-complete.dto';
import { AdminOnboardingCreateDto } from './dto/admin-onboarding-create.dto';

export const AdminOnboardingEndpoints = {
  GET_ONE: (schoolId: string, version: GUIDE_VERSION) => ({
    url: `/admin-onboarding/${schoolId}/${version}`,
    method: BackendMethod.GET,
  }),
  MARK_AS_COMPLETED: (schoolId: string, data: AdminOnboardingCompletedDto) => ({
    url: `/admin-onboarding/${schoolId}/completed`,
    method: BackendMethod.POST,
    data: data,
  }),
  CREATE: (schoolId: string, data: AdminOnboardingCreateDto) => ({
    url: `/admin-onboarding/${schoolId}`,
    method: BackendMethod.POST,
    data: data,
  }),
  DROP_OFF: (schoolId: string, version: GUIDE_VERSION) => ({
    url: `/admin-onboarding/${schoolId}/${version}/drop-off`,
    method: BackendMethod.PATCH,
  }),
};
