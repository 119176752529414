import { z } from 'zod';

export enum TASK_TUTORIAL_TYPE {
  VIDEO = 'video',
  IMAGE = 'image',
  TEXT = 'text',
  FILE = 'file',
}

export enum GUIDE_VERSION {
  GUIDE_2024_08_19 = '2024-08-19',
}

const OnboardingTask = z
  .object({
    taskId: z.string(),
    taskTitle: z.string(),
    taskDescription: z.string(),
    taskLink: z.string(),
    taskCta: z.string(),
    taskOrder: z.number(),
    taskCompleted: z.date().nullable(),
    taskShortMedia: z.string().nullable(),
    taskTutorialMedia: z.string().nullable(),
    taskTutorialType: z.nativeEnum(TASK_TUTORIAL_TYPE).nullable(),

    groupId: z.string(),
    groupOrder: z.number(),
    groupTitle: z.string(),
    groupIcon: z.string().nullable(),
  })
  .strict();

const AdminGuide2024 = z
  .object({
    version: z.literal(GUIDE_VERSION.GUIDE_2024_08_19),
    title: z.string(),
    description: z.string().nullable(),
    tasks: z.array(OnboardingTask),
  })
  .strict();

// const AdminGuide2025 = z
//   .object({
//     version: z.literal('2025-01-01'),
//     title: z.string(),
//     description: z.string().nullable(),
//     tasks: z.array(OnboardingTask),
//   })
//   .strict();

export const Guide = z.discriminatedUnion('version', [
  AdminGuide2024,
  // AdminGuide2025,
]);

export const AdminOnboarding = z
  .object({
    schoolId: z.string(),
    uid: z.string(),
    hidden: z.date().optional(),
    guide: Guide,
  })
  .strict();

export type AdminOnboarding = z.infer<typeof AdminOnboarding>;
export type AdminGuide2024 = z.infer<typeof AdminGuide2024>;
export type OnboardingTask = z.infer<typeof OnboardingTask>;
